@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

::-webkit-scrollbar-track,
ant-table-body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 50px;
}

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgb(191 219 254);
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 10;
}

.leaflet-left {
  display: none;
}

.leaflet-top.leaflet-right {
  top: 50px !important;
}

.glass {
  border: 1px solid #ededed;
  background: linear-gradient(
    180deg,
    rgba(28, 28, 28, 0.1) 0%,
    rgba(28, 28, 28, 0.05) 100%
  );
  backdrop-filter: blur(10px);
}

.ant-table {
  font-size: 16px !important;
}

.ant-table-title {
  padding: 10px 16px !important;
}

.ant-table-cell {
  font-weight: 500;
  padding: 10px 16px !important;
}

.ant-table-thead > tr > th {
  background-color: #bedbfe !important;
}

.table-row:hover,
.table-row:hover > td {
  background-color: #e6f7ff !important;
}

.table-info:hover,
.table-info:hover > td {
  background-color: none !important;
}

.mapDeviceList {
  scroll-behavior: smooth;
}

.ant-steps-item {
  padding: 0 !important;
}

.leaflet-popup-content p {
  padding: 0 !important;
  margin: 4px !important;
}

@media only screen and (max-width: 768px) {
  .ant-table-cell {
    font-size: 12px !important;
    white-space: nowrap;
  }

  .ant-table {
    font-size: 12px !important;
  }
}
